/** composable for using @/components/date-range-picker/date-range-picker.vue along with saving result in URL */

import {
  shallowRef, watch,
} from 'vue';
import {
  useRoute,
} from 'vue-router';
import {
  useToast,
} from 'primevue/usetoast';
import {
  i18n,
} from '@i18n';
import {
  DEFAULT_RANGES_LIST,
  IDateInterval,
  rangeIdToRange,
  rangeToDateInterval,
  RangeZod,
  TChoice,
  TRange,
} from '@/components/date-range-picker/date-range-picker.ts';

export const RANGE_QUERY_KEY = 'range';

export function useQueryDateRangePicker({
  defaultRange,
}: {
  defaultRange?: TChoice,
} = {}) {
  const route = useRoute();

  const activeRange = shallowRef<TRange>(
    (
      typeof defaultRange === 'string'
        ? rangeIdToRange(defaultRange)
        : defaultRange
    )
      || rangeIdToRange(DEFAULT_RANGES_LIST[0]),
  );
  // We can not use computed here, as a relative range would never change, but the actual interval needs to change.
  const activeInterval = shallowRef<IDateInterval>(rangeToDateInterval(activeRange.value));

  function setQueryParam(range: TRange) {
    const query = new URLSearchParams(route.fullPath.split('?')[1] || '');
    query.set(RANGE_QUERY_KEY, JSON.stringify(range));
    const newPath = `${route.fullPath.split('?')[0]}?${query.toString()}`;
    // we do this in order NOT to trigger router hooks
    window.history.replaceState(window.history.state, '', newPath);
    // we do it in order to update reactive object
    route.query.range = query.get(RANGE_QUERY_KEY);
  }

  // Read the range from the query is present
  if (route.query[RANGE_QUERY_KEY]) {
    let parseResult;
    try {
      parseResult = RangeZod.safeParse(JSON.parse(route.query[RANGE_QUERY_KEY] as string));
    } catch (e) {
      parseResult = {
        success: false,
        error: {
          message: (e as SyntaxError).message || `Failed to parse '${RANGE_QUERY_KEY}' URL query param as JSON: Invalid Syntax.`,
        },
      };
    }

    if (parseResult.success) {
      activeRange.value = parseResult.data as TRange;
      activeInterval.value = rangeToDateInterval(activeRange.value);
    } else {
      useToast().add({
        severity: 'warn',
        life: 5000,
        closable: true,
        summary: i18n.global.t('useQueryDateRange.parseFailureHeadline'),
        detail: i18n.global.t('useQueryDateRange.parseFailureDetails'),
      });
    }
  } else {
    setQueryParam(activeRange.value);
  }

  watch(() => activeRange.value, (newValue) => {
    activeInterval.value = rangeToDateInterval(activeRange.value);
    setQueryParam(newValue);
  });

  return {
    activeRange,
    activeInterval,
  };
}
